<template>
  <div>
    <div :class="prefix + '-image-container'" v-if="imageUrl">
      <div :class="[prefix + '-image-wrapper', prefix + '-image-animate']">
        <img :src="imageUrl" :width="imageWidth + 'px'" />
      </div>
      <div :class="prefix + '-image-name'">{{ label }}</div>
    </div>
    <!-- 今日实时里程 -->
    <div class="miles" v-else>
      <div class="bg_m1 m1-anim"></div>
      <div class="bg_m2 m2-anim"></div>
      <div class="bg_m3 m3-anim"></div>
      <div class="bg_m4 m4-anim"></div>
      <div class="mile_txt">
        <div class="num">{{ miles }}</div>
        <div class="km">km</div>
        <div class="txt">{{ label }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    prefix: {
      type: String,
      default: () => {}
    },
    imageWidth: {
      type: Number,
      default: () => {}
    },
    label: {
      type: String,
      default: () => {}
    },
    miles: {
      type: Number,
      default: () => {}
    }
  },
  computed: {
    imageUrl() {
      if (this.prefix === 'cleaner') {
        return this.cleanerImageUrl
      } else if (this.prefix === 'vehicle') {
        return this.vehicleImageUrl
      } else {
        return ''
      }
    }
  },
  data() {
    return {
      cleanerImageUrl: require('@/assets/images/dashboard/ico-staff.png'),
      vehicleImageUrl: require('@/assets/images/dashboard/ico-eqm.png')
    }
  }
}
</script>

<style lang="less" scoped>
@keyframes cleaner-animate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
// cleaner
.cleaner-image-container {
  width: 50px;
  height: 50px;
  background: url('../../../../assets/images/dashboard/bg-staff.png') no-repeat center;
  background-size: 100% 100%;
}

.cleaner-image-wrapper {
  text-align: center;
  padding-top: 10px;
}

.cleaner-image-animate {
  animation: cleaner-animate 2s linear infinite;
}
.cleaner-image-name {
  text-align: center;
  padding-top: 20px;
}

// vehicle
@keyframes vehicle-animate {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(28px);
  }
}
.vehicle-image-container {
  width: 50px;
  height: 50px;
  background: url('../../../../assets/images/dashboard/bg-eqm.png') no-repeat center;
  background-size: 100% 100%;
}
.vehicle-image-wrapper {
  text-align: center;
  padding-top: 0px;
}

.vehicle-image-name {
  text-align: center;
  padding-top: 40px;
}

.vehicle-image-animate {
  animation: vehicle-animate 2s linear infinite;
}

//里程
.bg_m1,
.bg_m2,
.bg_m3,
.bg_m4 {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0;
}
.bg_m1 {
  background: url('../../../../assets/images/dashboard/bg-m1.png') no-repeat center;
  background-size: 100% 100%;
}
.bg_m2 {
  background: url('../../../../assets/images/dashboard/bg-m2.png') no-repeat center;
  background-size: 100% 100%;
}
.bg_m3 {
  background: url('../../../../assets/images/dashboard/bg-m3.png') no-repeat center;
  background-size: 100% 100%;
}
.bg_m4 {
  background: url('../../../../assets/images/dashboard/bg-m4.png') no-repeat center;
  background-size: 100% 100%;
}
.mile_txt {
  width: 80px;
}
.mile_txt .num {
  padding-top: 25px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #00fff1;
}
.mile_txt .km {
  text-align: center;
  color: #00fff1;
  line-height: 5px;
}
.mile_txt .txt {
  padding-top: 30px;
  text-align: center;
  font-size: 8px;
}

.m1-anim {
  animation: rotateVbg_m1 3s linear infinite;
}
@keyframes rotateVbg_m1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.m2-anim {
  animation: rotateVbg_m2 3s linear infinite;
}
@keyframes rotateVbg_m2 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-60deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.m3-anim {
  animation: rotateVbg_m3 8s linear infinite;
}
@keyframes rotateVbg_m3 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.m4-anim {
  animation: rotateVbg_m4 12s linear infinite;
}
@keyframes rotateVbg_m4 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.miles {
  margin-left: 20px;
  width: 80px;
  height: 90px;
  // float: left;
  position: relative;
}
</style>
