var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.imageUrl
      ? _c("div", { class: _vm.prefix + "-image-container" }, [
          _c(
            "div",
            {
              class: [
                _vm.prefix + "-image-wrapper",
                _vm.prefix + "-image-animate",
              ],
            },
            [
              _c("img", {
                attrs: { src: _vm.imageUrl, width: _vm.imageWidth + "px" },
              }),
            ]
          ),
          _c("div", { class: _vm.prefix + "-image-name" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
        ])
      : _c("div", { staticClass: "miles" }, [
          _c("div", { staticClass: "bg_m1 m1-anim" }),
          _c("div", { staticClass: "bg_m2 m2-anim" }),
          _c("div", { staticClass: "bg_m3 m3-anim" }),
          _c("div", { staticClass: "bg_m4 m4-anim" }),
          _c("div", { staticClass: "mile_txt" }, [
            _c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.miles))]),
            _c("div", { staticClass: "km" }, [_vm._v("km")]),
            _c("div", { staticClass: "txt" }, [_vm._v(_vm._s(_vm.label))]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }